import React, { useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import axiosInstance from './axiosConfig';

const CreateNewClient = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [form] = Form.useForm();

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        form.resetFields();
    };

    const handleSubmit = async (values) => {
        try {
            const response = await axiosInstance.post('/create-client', values);
            console.log(response.data);
            message.success('Client created successfully');
            closeModal();
        } catch (error) {
            console.error('Error creating client:', error);
            message.error('Error creating client');
        }
    };

    return (
        <div>
            <Button type="primary" onClick={openModal}>Create New Client</Button>
            <Modal
                visible={isOpen}
                title="Create New Client"
                onCancel={closeModal}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter the first name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter the last name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Company Name"
                        name="companyName"
                        rules={[{ required: true, message: 'Please enter the company name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default CreateNewClient;
