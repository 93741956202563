// phoneUtils.js

/**
 * Normalizes a phone number to the format: "18001231234"
 * If no country code is present, assumes country code '1'.
 *
 * @param {string} input - The phone number in various possible formats.
 * @returns {string|null} - The normalized phone number or null if invalid.
 */
export function normalizePhoneNumber(input) {
    if (!input) return null;
  
    // Remove all non-digit characters
    const digits = input.replace(/\D/g, '');
  
    let normalized = '';
  
    if (digits.length === 10) {
      // No country code, assume '1'
      normalized = '1' + digits;
    } else if (digits.length === 11 && digits.startsWith('1')) {
      // Already has country code '1'
      normalized = digits;
    } else if (digits.length > 11 && digits.startsWith('1')) {
      // More than 11 digits but starts with '1', take first 11 digits
      normalized = digits.substring(0, 11);
    } else if (digits.length > 10) {
      // Assume the first digits are country code
      normalized = digits;
    } else {
      // Invalid number
      return null;
    }
  
    return normalized;
  }