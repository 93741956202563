import React, { useState } from 'react';
import { Button, Form, Input, Select, message, Typography, Watermark } from 'antd';
import axiosInstance from './axiosConfig'; // Use your configured axios instance

const { Title } = Typography;

function DataGatherer() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleGatherData = async (values) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/gather-data', values);
      message.success('Data gathered successfully!');
      // Optionally, handle the response data
      console.log(response.data);
    } catch (error) {
      console.error('Error gathering data:', error);
      message.error(
        error.response?.data?.error || 'Failed to gather data. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

return (
    <>
        <Watermark content="Placeholder" gap={[50, 50]} font={{ color: '#FFA500' }}>
            <div className="grid-item-content">
                <Form form={form} onFinish={handleGatherData} layout="vertical">
                    <Form.Item
                        name="keywords"
                        label="Keywords"
                        rules={[{ required: true, message: 'Please enter keywords' }]}
                    >
                        <Input placeholder="e.g., marketing agencies, software companies" />
                    </Form.Item>
                    <Form.Item
                        name="location"
                        label="Location"
                        rules={[{ required: true, message: 'Please enter a location' }]}
                    >
                        <Input placeholder="e.g., New York, NY" />
                    </Form.Item>
                    <Form.Item
                        name="maxResults"
                        label="Max Results"
                        rules={[
                            { required: true, message: 'Please enter the maximum number of results' },
                            { type: 'number', min: 1, max: 1000 },
                        ]}
                    >
                        <Input type="number" placeholder="e.g., 100" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Gather Data
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Watermark>
    </>
);
}

export default DataGatherer;