// axiosConfig.js
import axios from 'axios';

// Create an Axios instance
export const axiosInstance = axios.create({
  baseURL: 'https://pbj.digital', // Replace with your backend URL
});

export const axiosInstanceLive = axios.create({
    baseURL: 'https://gomatchbox.co', // Replace with your backend URL
  });

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    if (token) {
      // Attach the token to the Authorization header
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;