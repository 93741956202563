import $ from 'jquery';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // Import the CSS for styling
import { useState, useContext } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Modal, Notifications, notification } from 'antd';
import axios from 'axios';
import { updateAllContactStats, updateDisposition } from '../components/updateContactStats';
import axiosInstance from '../components/axiosConfig';
import { AuthContext } from '../AuthContext';
import { jwtDecode } from 'jwt-decode';
import { doesNotMatch } from 'assert';
import { startCalls } from '../components/dialer';
import { convertToProperJSON } from '../App';

const authToken = localStorage.getItem('authToken');

let iti;
let callStorage = {};
let timer = "00:00:00";
const incomingNotifications = new Map();
let isIncomingCallPresent = false;
const notyf = new Notyf();
let callUUID = null;
let callAnswerInfo = null;
let answeredContact = null;

export const defaultSettings = {
	debug: "INFO",
	permOnClick: false,
	codecs: ["OPUS", "PCMU"],
	audioConstraints: {
	  optional: [
		{ googAutoGainControl: true },
		{ googEchoCancellation: true },
		{ googNoiseSuppression: true },
	  ],
	},
	dscp: true,
	enableTracking: true,
	closeProtection: false,
	maxAverageBitrate: 48000,
	allowMultipleIncomingCalls: false,
	enableNoiseReduction: true,
	usePlivoStunServer: true,
	dtmfOptions: { sendDtmfType: ["outband", "inband"] },
  };

  const tools = [
    {
      type: "function",
      function: {
        name: "informationPuller",
        description:
          "Use this to format pulled information from the transcript. must be valid JSON",
        parameters: {
          type: "object",
          properties: {
            meetingSet: {
              type: "string",
              description: "respond 'true' or 'false'",
            },
            meetingInfo: {
              type: "string",
              description: "respond with {'time': (dayjs format date and time or null), 'people': (list first and last name separated by comma or null if none), 'meetingType': (Google, Teams, In person, etc or null)}",
            },
            calleeEmail: {
              type: "string",
              description: "respond with email formatted or null",
            },
            calleeName: {
              type: "string",
              description: "respond with first and last name of the person that answers or null",
            },
            followUpCallSet: {
              type: "string",
              description: "respond 'true' or 'false'",
            },
            followUpInfo: {
              type: "string",
              description: "respond with {'time': (dayjs format date and time or null), 'person': (list first and last name separated by comma or null if none), 'number': (format +123456790 or +12345678901 or null)}",
            },
            disposition: {
              type: "string",
              description: "out of these options, pick the best match, Hung up is only if prospect hangs up while being asked a question or while being talked to, Not Me is for is prospect doesn't do what you ask but is the correct contact: 'Not interested', 'Bad Data', 'Left Company', 'Follow Up', 'Hung Up', 'Not Me', 'Retired', 'Do Not Call', 'Meeting Booked'",
            }
          },
          required: ["meetingSet", "meetingInfo", "calleeEmail", "calleeName", "followUpCallSet", "followUpInfo"],
        },
      },
    },
  ];

  export const onCallTerminated = (evt, callInfo,  setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint) => {
    const token = localStorage.getItem('authToken');
    const decoded = jwtDecode(token);
    
    document.getElementById('hangupButton').hidden = true;
    document.getElementById('noAnswer').hidden = true;
    $('#callstatus').html('Call Ended');
    console.info('onCallTerminated', evt);
    localStorage.setItem('callInfo', {});
    localStorage.removeItem('callInfo');   
    console.log(document.querySelector("#toNumber").value);
    if (document.querySelector("#toNumber").value == ""){
      updateAllContactStats();
      console.log('updateAllContactStats');
      setTimeout(() => {
        axiosInstance.get('/get-lists')
          .then((response) => {
            let fetchedLists = response.data;
            console.log('Fetched Lists:', fetchedLists);

            // Updated filter logic to handle multiple assignments
            fetchedLists = fetchedLists.filter((list) => {
              if (!list.assignedTo) return false; // Exclude if `assignedTo` is empty or null

              // Split the `assignedTo` string into an array of trimmed IDs
              const assignedIds = list.assignedTo.split(',').map(id => id.trim());

              // Check if the current user's ID is in the array
              return assignedIds.includes(decoded.userId.toString());
            });
            setLists(fetchedLists);
            console.log('Filtered Lists:', fetchedLists);
            console.log('Selected List ID:', selectedListId);
            // if (fetchedLists.length > 0 && !selectedListId) {
              //   // Set initial selected list ID to the first one if none is selected
              //   setSelectedListId(fetchedLists[0].ListID);
              // }
              
              // Find the list that matches the selectedListId
              const selectedList = fetchedLists.find(list => list.ListID === selectedListId);
              
            
            if (selectedList) {
              const numbers = selectedList.items || []; // Ensure `items` exists

              setUnfilteredMatchbox(selectedList);

              const filteredMatchbox = filterList(selectedList);
              setSelectedMatchbox(filteredMatchbox);
              localStorage.setItem('selectedMatchbox', JSON.stringify(filteredMatchbox));

              // Extract the first three phone numbers to be pushed
              const numsToBePushed = numbers.slice(0, 3).map(contact => contact.kixie_phone_number);
              pushNumbersToEndpoint(numsToBePushed);

              console.log('Selected List:', selectedList);
            } else {
              console.warn('List with the selectedListId not found.');
            }
          })
          .catch((error) => {
            console.error('Error fetching lists:', error);
                    });


      }, 1000); // Simulating a 1-second fetch delay
    } else {
    document.querySelector("#toNumber").value = null;
    }
    plivoBrowserSdk.client.hangup();

    document.getElementById('AILoading').hidden = false;
    document.getElementById('Summary').hidden = true;

    setTimeout(() => {
    axios.get(`https://api.plivo.com/v1/Account/MANGFJMTYWOGU1NJVLZJ/Recording/?call_uuid=${callUUID}`, {
      auth: {
      username: 'MANGFJMTYWOGU1NJVLZJ',
      password: 'ZDdkYTkzNWFlZjRlYzI2NjhhYjU5Mjg2NjkzZDhk'
      }
    }).then((response) => {
      console.log(response);
      console.log(callAnswerInfo);
      console.log(answeredContact);
      let callid = response.data.objects[0].call_uuid;
      let calldate = response.data.objects[0].add_time;
      let fromnumber = callAnswerInfo.DialBLegFrom;
      let fname = decoded.username;
      let lname = "IAD Growth";
      let tonumber = callAnswerInfo.DialBLegTo;
      let duration = (response.data.objects[0].recording_duration_ms / 1000).toFixed();
      let recordingurl = response.data.objects[0].recording_url;
      let calleridName = decoded.username;
      let email = "@iadgrowth.com";
      let matchBoxId = JSON.parse(localStorage.getItem('selectedMatchbox')).listID;
      let userId = decoded.userId;

      axios.post(`https://layerloom.com/save-call-details?callid=${callid}&calldate=${calldate}&fromnumber=${fromnumber}&tonumber=${tonumber}&duration=${duration}&recordingurl=${recordingurl}&calleridName=${calleridName}&email=${email}&matchBoxId=${matchBoxId}&fname=${fname}&lname=${lname}&userId=${userId}`, {
        callid,
        calldate,
        fromnumber,
        lname,
        fname,
        tonumber,
        duration,
        recordingurl,
        calleridName,
        email,
        matchBoxId,
        userId
      })
        .then((responseblob) => {
    axios.get(recordingurl, { responseType: 'arraybuffer' })
      .then(response => {
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const formData = new FormData();
      formData.append('file', audioBlob, 'audio.mp3');
      formData.append('model', 'whisper-1');

      axios.post(
        'https://api.openai.com/v1/audio/transcriptions',
        formData,
        {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8`,
        },
        }
      )
        .then(transcriptResponse => {
        const transcriptText = transcriptResponse.data.text;
        let Transcript = (transcriptResponse.data.text);

        axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
          model: 'gpt-4o-mini',
          messages: [{ role: "system", content: `Summarize the following call transcript's result in a list of bullet points separated with line breaks:\n\n${transcriptText}\n\nSummary:` }],
          max_tokens: 120,
          },
          {
          headers: { 'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8` },
          }
        )
          .then(summaryResponse => {
          let Summary = (summaryResponse.data.choices[0].message.content.trim());


          axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
            model: 'gpt-4o-mini',
            messages: [{ role: "system", content: `call end time (keep this in mind for meeting times, meeting times are set after this date, and WILL NOT BE SET IN THE PAST. TOMORROW WOULD BE THE DAY +1): ` + Date() + `, called number(if followup made but no number mentioned): (`+ tonumber +`) Pull important information from the call and return it using the informationPuller but only run it once to keep the information tidy:\n\n${transcriptText}\n\nSummary:` }],
            max_tokens: 120,
            tools: tools,
            },
            {
            headers: { 'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8` },
            }
          )
            .then(pulledData => {
            let pulledDataCleaned = (pulledData.data.choices[0].message.tool_calls[0]);
            console.log(JSON.parse(pulledData.data.choices[0].message.tool_calls[0].function.arguments));

            console.log(responseblob);
          responseblob.data = {
            ...responseblob.data,
            transcript: transcriptResponse,
            summary: summaryResponse,
            pulledData: pulledData,
          };
          axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
          .then((response) => {console.log(response);
            document.getElementById('AILoading').hidden = true;
            document.getElementById('Summary').hidden = false;
            document.getElementById('Summary').innerHTML = Summary;
            let answeredContactId = document.getElementById('answeredContactId').innerHTML;
            console.log(answeredContactId);
            console.log("disposition",convertToProperJSON(pulledData.data?.choices[0]?.message?.tool_calls[0]?.function?.arguments)?.disposition);
            updateDisposition(answeredContactId, convertToProperJSON(pulledData.data?.choices[0]?.message?.tool_calls[0]?.function?.arguments)?.disposition);
            document.getElementById('dispositionFromAI').innerHTML = convertToProperJSON(pulledData.data?.choices[0]?.message?.tool_calls[0]?.function?.arguments)?.disposition;
          }).catch((error) => {console.error(error);});
            })
            .catch(error => {

            console.error('Error fetching or transcribing audio:', error);
            responseblob.data = {
              ...responseblob.data,
              transcript: '',
              summary: '',
              pulledData: '',
            };
            axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
            .then((response) => {console.log(response);
              
            }).catch((error) => {console.error(error);});
            });
          })
          .catch(error => {

          console.error('Error fetching or transcribing audio:', error);
          responseblob.data = {
              ...responseblob.data,
              transcript: '',
              summary: '',
              pulledData: '',
            };
            axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
            .then((response) => {console.log(response);
              document.getElementById('AILoading').hidden = true;
              document.getElementById('Summary').hidden = false;
            }).catch((error) => {console.error(error);document.getElementById('AILoading').hidden = true;});
          });
        })
        .catch(error => {

        console.error('Error fetching or transcribing audio:', error);
        responseblob.data = {
          ...responseblob.data,
          transcript: '',
          summary: '',
          pulledData: '',
        };
        axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
        .then((response) => {console.log(response);
          document.getElementById('AILoading').hidden = true;
          document.getElementById('Summary').hidden = false;
          
        }).catch((error) => {console.error(error);});
        });
      })
      .catch(error => {

      console.error('Error fetching or transcribing audio:', error);
      responseblob.data = {
        ...responseblob.data,
        transcript: '',
        summary: '',
        pulledData: '',
      };
      axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
      .then((response) => {console.log(response);
        
      }).catch((error) => {console.error(error);});
      });
          
        })
        .catch((error) => {
          console.error(error);
          document.getElementById('AILoading').hidden = true;
        });

    });

  }, 5000);
    if (callInfo && callInfo.callUUID === plivoBrowserSdk.client.getCallUUID()) {
      console.info(JSON.stringify(callInfo));
      callOff(evt);
    } else if (!callInfo) {
      callOff(evt);
    }
  };

  let incomingCallInfo = null;
let incomingNotificationAlert = null;

export const setIncomingCallStatus = (status) => {
  isIncomingCallPresent = status;
};

export const getIncomingCallStatus = () => {
  return isIncomingCallPresent;
};

export const onLogout = () => {
	console.info('onLogout');
	performLogout();
  };
  
  const performLogout = () => {
	document.body.style.backgroundImage = 'url(img/background.svg)';
	$('#loginContainer').show();
	$('#callContainer').hide();
	$('.loader').hide();
	$('#toNumber').val("");
	if (iti) iti.setCountry("us");
	localStorage.clear();
  };
  
  export const onCallAnswered = (callInfo, updateMatchedContact) => {
    const decoded = jwtDecode(authToken);
        const User ={
          id: decoded.userId,
          username: decoded.username,
          role: decoded.role,
          companyId: decoded.companyId,
          // Add other relevant fields from your JWT payload
        };

    console.info('onCallAnswered');
    if (callInfo) console.info(JSON.stringify(callInfo));

  setTimeout(() => {
    // Code to be executed after the delay
    axiosInstance.get('/get-call-info/')
      .then((response) => {
        callInfo = response.data;
        callUUID = response.data.CallUUID;
        callAnswerInfo = response.data;
        $('#callstatus').html('Answered');
        $('.hangup').show();
        if (callInfo && callInfo.direction === 'incoming') {
          $('#phone').hide();
          $('#boundType').html('Incoming : ' + callInfo.src);
          $('#callNum').html(callInfo.src);
          $('#callDuration').html('00:00:00');
          $('.callinfo').show();
          const noiseReduction = document.getElementById('ongoingNoiseReduction');
          document.getElementById('callanswerpad').appendChild(noiseReduction);
          if (incomingNotifications.has(callInfo.callUUID)) {
            const incomingCall = incomingNotifications.get(callInfo.callUUID);
            if (incomingCall) incomingCall.hide();
            incomingNotifications.delete(callInfo.callUUID);
          }
        }
        timer = 0;
  
        // Fetch contact information based on DialBLegTo number
        const dialBLegTo = callInfo.DialBLegTo;
        localStorage.setItem('answeredNumber', dialBLegTo);
        axiosInstance.get(`/api/numberCall?number=${dialBLegTo}`)
          .then((contactResponse) => {
            const contactInfo = contactResponse.data.contact;
            answeredContact = contactResponse.data;
            // Show modal with callInfo and contactInfo
              console.log(updateMatchedContact)
              updateMatchedContact(contactResponse.data);
              console.log(contactResponse.data);
              console.log(document.getElementById('answeredContactName'));

              document.getElementById('answeredContactName').innerHTML = contactInfo.first_name + ' ' + contactInfo.last_name;
              document.getElementById('answeredContactCompany').innerHTML = contactInfo.company;
              document.getElementById('answeredContactTitle').innerHTML = contactInfo.title;
              document.getElementById('answeredContactEmail').innerHTML = contactInfo.email;
              document.getElementById('answeredContactNumber').innerHTML = contactInfo.phone_number;
              document.getElementById('answeredContactCustom1').innerHTML = contactInfo.custom_field_1;
              document.getElementById('answeredContactCustom2').innerHTML = contactInfo.custom_field_2;
              document.getElementById('answeredContactCustom3').innerHTML = contactInfo.custom_field_3;
              document.getElementById('answeredContactCustom4').innerHTML = contactInfo.custom_field_4;
              document.getElementById('answeredContactCustom5').innerHTML = contactInfo.custom_field_5;
              document.getElementById('answeredContactId').innerHTML = contactInfo.id;
              document.getElementById('NoteEditor').innerHTML = contactInfo.notes ? contactInfo.notes : '';

              axiosInstance.get(`/getNote/?contactId=${contactInfo.id}&companyId=${User.companyId}`).then((response)=>{
                document.getElementById('NoteEditor').innerHTML = response?.data?.note?.note ? response?.data?.note?.note : '';
              }).catch((error)=>{});

            // Modal.info({
            //   title: 'Call Information',
            //   content: (
            //     <div>
            //     <p><strong>Contact Information:</strong></p>
            //     <p><strong>First Name:</strong> {contactInfo.first_name || 'N/A'}</p>
            //     <p><strong>Last Name:</strong> {contactInfo.last_name || 'N/A'}</p>
            //     <p><strong>Title:</strong> {contactInfo.title || 'N/A'}</p>
            //     <p><strong>Company:</strong> {contactInfo.company || 'N/A'}</p>
            //     <p><strong>Email:</strong> {contactInfo.email || 'N/A'}</p>
            //     <p><strong>Phone Number:</strong> {contactInfo.phone_number || 'N/A'}</p>
            //     <p><strong>Town:</strong> {contactInfo.town || 'N/A'}</p>
            //     <p><strong>Postal:</strong> {contactInfo.postal || 'N/A'}</p>
            //     <p><strong>State:</strong> {contactInfo.state || 'N/A'}</p>
            //     <p><strong>Address:</strong> {contactInfo.address || 'N/A'}</p>
            //     {contactInfo.url && (
            //       <p>
            //         <strong>URL:</strong> <a href={contactInfo.url} target="_blank" rel="noopener noreferrer">View Contact</a>
            //       </p>
            //     )}
            //     {contactInfo.contact_id && (
            //       <p><strong>Contact ID:</strong> {contactInfo.contact_id}</p>
            //     )}
            //   </div>
          
            //   ),
            //   onOk() {},
            // });
          })
          .catch((contactError) => {
            console.error('Error fetching contact info:', contactError);
          });
      })
      .catch((error) => {
        console.error('Error fetching call info:', error);
      });
  }, 500); // 1000 milliseconds = 1 second
  };

  export const onLoginFailed = (reason) => {
	console.info('onLoginFailed', reason);
	if (Object.prototype.toString.call(reason) === "[object Object]") {
	  reason = JSON.stringify(reason);
	}
	customAlert('Login failure:', reason, 'warn');
	$('.loader').hide();
  };

  export const onLogin = (username, password) => {
	if (!plivoBrowserSdk) {
	  console.error('Plivo client is not initialized.');
	  return;
	}
  
	const client = plivoBrowserSdk.client;
	
	client.login(username, password);
	console.log(client)
	if (client && client.userName && client.phone && client.phone.configuration) {
	  document.getElementById('loginContainer').style.display = 'none';
	  document.getElementById('callContainer').style.display = 'block';
	  document.body.style.backgroundImage = 'none';
	  console.info('Logged in');
  
	  const customCallerId = localStorage.getItem('callerId');
	  if (customCallerId) {
		const callerid = document.getElementById("callerid");
		callerid.value = customCallerId;
	  }
  
	  client.audio.speakerDevices.set('default');
	  customAlert("connected", "info", 'info');
	} else {
	  console.error('Plivo client is not properly initialized.');
	  customAlert('Login Error', 'Plivo client is not properly initialized.', 'warn');
	}
  };
  
  

export const onWebrtcNotSupported = () => {
	console.warn('No WebRTC support');
	alert('WebRTC is not supported in this browser. Please use the latest version of Chrome, Firefox, Opera, or IE Edge.');
  };

export const setIti = (instance) => {
  iti = instance;
};

export const onIncomingCall = (callerName, extraHeaders, callInfo, caller_Name, setIncomingCallStatus, setCallerDetails) => {
	console.info('onIncomingCall:', callerName, extraHeaders, callInfo, caller_Name);
  
	setIncomingCallStatus(true);
	setCallerDetails({ callerName, callInfo });
  
	// Store call information
	callStorage = {
	  startTime: new Date().toISOString(),
	  mode: 'in',
	  num: caller_Name,
	};
  
	// Display notification using Notyf
  notyf.success(`Incoming Call: ${caller_Name}`);
  
	// Store the call information for later use
	if (callInfo) {
	  incomingNotifications.set(callInfo.callUUID, null);
	  incomingCallInfo = callInfo;
	}
  
	// Example: You could add a way to show call options to the user
	showCallOptions(callInfo, setIncomingCallStatus);
  };
  
  const showCallOptions = (callInfo, setIncomingCallStatus) => {
	// Display the call options: Answer, Reject, Ignore
	const handleAnswer = () => {
	  setIncomingCallStatus(false);
	  if (callInfo) {
		plivoBrowserSdk.client.answer(callInfo.callUUID);
	  } else {
		plivoBrowserSdk.client.answer();
	  }
	};
  
	const handleReject = () => {
	  setIncomingCallStatus(false);
	  if (callInfo) {
		plivoBrowserSdk.client.reject(callInfo.callUUID);
	  } else {
		plivoBrowserSdk.client.reject();
	  }
	};
  
	const handleIgnore = () => {
	  setIncomingCallStatus(false);
	  if (callInfo) {
		plivoBrowserSdk.client.ignore(callInfo.callUUID);
	  } else {
		plivoBrowserSdk.client.ignore();
	  }
	};
  
	// This is where you would show a UI (e.g., a modal or buttons in the component) to handle these actions
	console.log('Show call options: Answer, Reject, Ignore');
  };

export const trimSpace = (inputElement) => {
  inputElement.value = inputElement.value.replace(/[- ()]/g, '');
};

let plivoBrowserSdk;

export const initPhone = (updateMatchedContact, setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint) => {
  const options = refreshSettings();
  plivoBrowserSdk = new window.Plivo(options);

  plivoBrowserSdk.client.on('onWebrtcNotSupported', onWebrtcNotSupported);
  plivoBrowserSdk.client.on('onLogin', onLogin);
  plivoBrowserSdk.client.on('onLogout', onLogout);
  plivoBrowserSdk.client.on('onLoginFailed', onLoginFailed);
  plivoBrowserSdk.client.on('onCallRemoteRinging', onCallRemoteRinging);
  plivoBrowserSdk.client.on('onIncomingCallCanceled', onIncomingCallCanceled);
  plivoBrowserSdk.client.on('onIncomingCallIgnored', onIncomingCallIgnored);
  //plivoBrowserSdk.client.on('onCallFailed', onCallFailed);
  plivoBrowserSdk.client.on('onMediaConnected', onMediaConnected);
  plivoBrowserSdk.client.on('onCallAnswered', (callInfo) => onCallAnswered(callInfo, updateMatchedContact));
  plivoBrowserSdk.client.on('onCallTerminated',(callInfo) =>  onCallTerminated("", callInfo,  setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint));
  plivoBrowserSdk.client.on('onCalling', onCalling);
  plivoBrowserSdk.client.on('onIncomingCall', onIncomingCall);
  plivoBrowserSdk.client.on('onMediaPermission', onMediaPermission);
  plivoBrowserSdk.client.on('remoteAudioStatus', remoteAudioStatus);
  plivoBrowserSdk.client.on('mediaMetrics', mediaMetrics);
  plivoBrowserSdk.client.on('audioDeviceChange', audioDeviceChange);
  plivoBrowserSdk.client.on('onPermissionDenied', onPermissionDenied);
  plivoBrowserSdk.client.on('onNoiseReductionReady', onNoiseReductionReady);
  plivoBrowserSdk.client.on('onConnectionChange', onConnectionChange);
  plivoBrowserSdk.client.on('onDtmfReceived', handleOnDtmfReceived);
  plivoBrowserSdk.client.on('volume', volume);

  plivoBrowserSdk.client.setRingTone(true);
  plivoBrowserSdk.client.setRingToneBack(false);
  plivoBrowserSdk.client.setConnectTone(false);
  plivoBrowserSdk.client.setDebug("ALL");

  checkBrowserComplaince(plivoBrowserSdk.client);
  starFeedback();
  console.log('initPhone ready!');
};

export const handleMakeCall = (toNumber) => {
  toNumber = toNumber ? toNumber : 1111111111; 
  document.getElementById('answeredContactName').innerHTML = "";
  document.getElementById('answeredContactCompany').innerHTML = "";
  document.getElementById('answeredContactTitle').innerHTML = "";
  document.getElementById('answeredContactEmail').innerHTML = "";
  document.getElementById('answeredContactNumber').innerHTML = "";
  document.getElementById('answeredContactCustom1').innerHTML = "";
  document.getElementById('answeredContactCustom2').innerHTML = "";
  document.getElementById('answeredContactCustom3').innerHTML = "";
  document.getElementById('answeredContactCustom4').innerHTML = "";
  document.getElementById('answeredContactCustom5').innerHTML = "";
  return new Promise((resolve, reject) => {
    if (!toNumber) {
      customAlert('Call Error', 'Please enter a phone number to call.', 'warn');
      return reject('No phone number provided');
    }
    // Initiating a call using Plivo SDK
    try {
      const extraHeaders = []; // Add any extra SIP headers here if needed
      const callUUID = plivoBrowserSdk.client.call(toNumber, extraHeaders);
      console.info('Calling ' + toNumber);
      resolve(callUUID);
      document.getElementById('hangupButton').hidden = false;
      document.getElementById('noAnswer').hidden = false;
    } catch (error) {
      console.error('Error making the call:', error);
      customAlert('Call Error', 'There was an error while trying to make the call.', 'error');
      reject(error);
    }
  });
};

export const plivoInstance = plivoBrowserSdk;  // Export plivoBrowserSdk


const refreshSettings = () => {
	const getSettings = localStorage.getItem('plivosettings');
	if (getSettings) {
	  const parsedSettings = JSON.parse(getSettings);
	  return parsedSettings;
	} else {
	  return defaultSettings;
	}
  };
  

const onCallRemoteRinging = (callInfo) => {
  $('#callstatus').html('Ringing...');
  console.info('onCallRemoteRinging');
};

const onIncomingCallCanceled = (callInfo) => {
  console.info('**Incoming Call Canceled:** User canceled the incoming call.');
  if (incomingNotifications.has(callInfo.callUUID)) {
    const incomingCallNotification = incomingNotifications.get(callInfo.callUUID);
    incomingNotifications.delete(callInfo.callUUID);
    if (incomingCallNotification) {
      incomingCallNotification.hide();
    }
  }
  if (incomingNotifications.size === 0 && !plivoBrowserSdk.client.getCallUUID()) {
    callOff();
  }
};

const onIncomingCallIgnored = (callInfo) => {
  console.info("onIncomingCallIgnored", callInfo);
  if (incomingNotifications.has(callInfo.callUUID)) {
    const incomingCallNotification = incomingNotifications.get(callInfo.callUUID);
    incomingNotifications.delete(callInfo.callUUID);
    if (incomingCallNotification) {
      incomingCallNotification.hide();
    }
  }
  if (incomingNotifications.size === 0 && !plivoBrowserSdk.client.getCallUUID()) {
    callOff();
  }
};

// const onCallFailed = (reason, callInfo) => {
//   if (callInfo) {
//     console.log(JSON.stringify(callInfo));
//     console.info(onCallFailed `${reason}`, `${callInfo.callUUID},${callInfo.direction}`);
//   } else {
//     console.info('onCallFailed', reason);
//   }
//   if (reason && /Denied Media/i.test(reason)) {
//     $('#callstatus').html('call failed');
//     $('#mediaAccessBlock').modal('show');
//   }
//   if (!callInfo) {
//     callOff(reason);
//     return;
//   }
//   if (incomingNotifications.has(callInfo.callUUID)) {
//     const incomingCall = incomingNotifications.get(callInfo.callUUID);
//     if (incomingCall) incomingCall.hide();
//     incomingNotifications.delete(callInfo.callUUID);
//   }
//   if (incomingNotifications.size === 0 && !plivoBrowserSdk.client.getCallUUID()) {
//     callOff(reason);
//   } else if (incomingNotifications.size === 0 && callInfo.direction === 'outgoing') {
//     callOff(reason);
//   }
// };

const onMediaConnected = (callInfo) => {
  if (callInfo) console.log(JSON.stringify(callInfo));
  if (callInfo && callInfo.direction === 'incoming') {
    $('#callstatus').html('Answered');
  }
  console.info('onMediaConnected');
};

const onCalling = () => {
  $('#callstatus').html('Progress...');
  console.info('onCalling');
};


  export const handleHangup = (setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint) => {
    const token = localStorage.getItem('authToken');
    const decoded = jwtDecode(token);
    document.getElementById('hangupButton').hidden = true;
    document.getElementById('noAnswer').hidden = true;
	try {
	  plivoBrowserSdk.client.hangup();
	  console.info('Call ended by user');
	} catch (error) {
	  console.error('Error hanging up the call:', error);
	  customAlert('Hangup Error', 'There was an error while trying to hang up the call.', 'error');
	}
  $('#callstatus').html('Call Ended');
  console.log(document.querySelector("#toNumber").value);
  if (document.querySelector("#toNumber").value == ""){
    updateAllContactStats();
    console.log('updateAllContactStats');
    setTimeout(() => {
      axiosInstance.get('/get-lists')
        .then((response) => {
          let fetchedLists = response.data;
          console.log('Fetched Lists:', fetchedLists);

          // Updated filter logic to handle multiple assignments
          fetchedLists = fetchedLists.filter((list) => {
            if (!list.assignedTo) return false; // Exclude if `assignedTo` is empty or null

            // Split the `assignedTo` string into an array of trimmed IDs
            const assignedIds = list.assignedTo.split(',').map(id => id.trim());

            // Check if the current user's ID is in the array
            return assignedIds.includes(decoded.userId.toString());
          });
          setLists(fetchedLists);
          console.log('Filtered Lists:', fetchedLists);
          console.log('Selected List ID:', selectedListId);
          // if (fetchedLists.length > 0 && !selectedListId) {
            //   // Set initial selected list ID to the first one if none is selected
            //   setSelectedListId(fetchedLists[0].ListID);
            // }
            
            // Find the list that matches the selectedListId
            const selectedList = fetchedLists.find(list => list.ListID === selectedListId);
            
          
          if (selectedList) {
            const numbers = selectedList.items || []; // Ensure `items` exists

            setUnfilteredMatchbox(selectedList);

            const filteredMatchbox = filterList(selectedList);
            setSelectedMatchbox(filteredMatchbox);
            localStorage.setItem('selectedMatchbox', JSON.stringify(filteredMatchbox));

            // Extract the first three phone numbers to be pushed
            const numsToBePushed = numbers.slice(0, 3).map(contact => contact.kixie_phone_number);
            pushNumbersToEndpoint(numsToBePushed);

            console.log('Selected List:', selectedList);
          } else {
            console.warn('List with the selectedListId not found.');
          }
        })
        .catch((error) => {
          console.error('Error fetching lists:', error);
                  });


    }, 1000); // Simulating a 1-second fetch delay
  } else {
  document.querySelector("#toNumber").value = null;
  }

  setTimeout(() => {
  axios.get(`https://api.plivo.com/v1/Account/MANGFJMTYWOGU1NJVLZJ/Recording/?call_uuid=${callUUID}`, {
    auth: {
    username: 'MANGFJMTYWOGU1NJVLZJ',
    password: 'ZDdkYTkzNWFlZjRlYzI2NjhhYjU5Mjg2NjkzZDhk'
    }
  }).then((response) => {
    console.log(response);
    console.log(callAnswerInfo);
    console.log(answeredContact);
    let callid = response.data.objects[0].call_uuid;
    let calldate = response.data.objects[0].add_time;
    let fromnumber = callAnswerInfo.DialBLegFrom;
    let fname = decoded.fname;
    let lname = decoded.lname;
    let tonumber = callAnswerInfo.DialBLegTo;
    let duration = (response.data.objects[0].recording_duration_ms / 1000).toFixed();
    let recordingurl = response.data.objects[0].recording_url;
    let calleridName = decoded.username;
    let email = decoded.email;
    let matchBoxId = JSON.parse(localStorage.getItem('selectedMatchbox')).listID;
    let userId = decoded.userId;

    axios.post(`https://layerloom.com/save-call-details?callid=${callid}&calldate=${calldate}&fromnumber=${fromnumber}&tonumber=${tonumber}&duration=${duration}&recordingurl=${recordingurl}&calleridName=${calleridName}&email=${email}&matchBoxId=${matchBoxId}&fname=${fname}&lname=${lname}&userId=${userId}`, {
      callid,
      calldate,
      fromnumber,
      lname,
      fname,
      tonumber,
      duration,
      recordingurl,
      calleridName,
      email,
      matchBoxId,
      userId
    })
      .then((responseblob) => {
  axios.get(recordingurl, { responseType: 'arraybuffer' })
    .then(response => {
    const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.mp3');
    formData.append('model', 'whisper-1');

    axios.post(
      'https://api.openai.com/v1/audio/transcriptions',
      formData,
      {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8`,
      },
      }
    )
      .then(transcriptResponse => {
      const transcriptText = transcriptResponse.data.text;
      let Transcript = (transcriptResponse.data.text);

      axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
        model: 'gpt-4o-mini',
        messages: [{ role: "system", content: `Summarize the following call transcript's result in a list of bullet points separated with line breaks:\n\n${transcriptText}\n\nSummary:` }],
        max_tokens: 120,
        },
        {
        headers: { 'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8` },
        }
      )
        .then(summaryResponse => {
        let Summary = (summaryResponse.data.choices[0].message.content.trim());


        axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
          model: 'gpt-4o-mini',
          messages: [{ role: "system", content: `call end time (keep this in mind for meeting times, meeting times are set after this date, and WILL NOT BE SET IN THE PAST. TOMORROW WOULD BE THE DAY +1): ` + Date() + `, called number(if followup made but no number mentioned): (`+ tonumber +`) Pull important information from the call and return it using the informationPuller but only run it once to keep the information tidy:\n\n${transcriptText}\n\nSummary:` }],
          max_tokens: 120,
          tools: tools,
          },
          {
          headers: { 'Authorization': `Bearer sk-proj-7enofT0yrSzM7OOZC7PbT3BlbkFJGmdCHqwBKiWpubOn6IA8` },
          }
        )
          .then(pulledData => {
          let pulledDataCleaned = (pulledData.data.choices[0].message.tool_calls[0]);
          console.log(JSON.parse(pulledData.data.choices[0].message.tool_calls[0].function.arguments));

          console.log(responseblob);
        responseblob.data = {
          ...responseblob.data,
          transcript: transcriptResponse,
          summary: summaryResponse,
          pulledData: pulledData,
        };
        axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
        .then((response) => {console.log(response);
          let answeredContactId = document.getElementById('answeredContactId').innerHTML;
          console.log(answeredContactId);
          console.log("disposition",convertToProperJSON(pulledData.data?.choices[0]?.message?.tool_calls[0]?.function?.arguments)?.disposition);
          updateDisposition(answeredContactId, convertToProperJSON(pulledData.data?.choices[0]?.message?.tool_calls[0]?.function?.arguments)?.disposition);
          document.getElementById('dispositionFromAI').innerHTML = convertToProperJSON(pulledData.data?.choices[0]?.message?.tool_calls[0]?.function?.arguments)?.disposition;
        }).catch((error) => {console.error(error);});
          })
          .catch(error => {

          console.error('Error fetching or transcribing audio:', error);
          responseblob.data = {
            ...responseblob.data,
            transcript: '',
            summary: '',
            pulledData: '',
          };
          axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
          .then((response) => {console.log(response);
            
          }).catch((error) => {console.error(error);});
          });
        })
        .catch(error => {

        console.error('Error fetching or transcribing audio:', error);
        responseblob.data = {
            ...responseblob.data,
            transcript: '',
            summary: '',
            pulledData: '',
          };
          axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
          .then((response) => {console.log(response);
            
          }).catch((error) => {console.error(error);});
        });
      })
      .catch(error => {

      console.error('Error fetching or transcribing audio:', error);
      responseblob.data = {
        ...responseblob.data,
        transcript: '',
        summary: '',
        pulledData: '',
      };
      axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
      .then((response) => {console.log(response);
        
      }).catch((error) => {console.error(error);});
      });
    })
    .catch(error => {

    console.error('Error fetching or transcribing audio:', error);
    responseblob.data = {
      ...responseblob.data,
      transcript: '',
      summary: '',
      pulledData: '',
    };
    axios.post('https://gomatchstick.co/webhook/data',responseblob.data)
    .then((response) => {console.log(response);
      
    }).catch((error) => {console.error(error);});
    });
        
      })
      .catch((error) => {
        console.error(error);
      });

  });
  localStorage.removeItem('answeredNumber');

}, 5000);
  };

  export const handleNoAnswerHangup = (setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint) => {
    localStorage.setItem('answeredNumber', '');
    handleHangup(setLists, selectedListId, setUnfilteredMatchbox, filterList, setSelectedMatchbox, pushNumbersToEndpoint);
    setTimeout(() => {
      startCalls();
    }, 2000);

    };
	

export const useIncomingCall = () => {
	const [isIncomingCallPresent, setIncomingCallStatus] = useState(false);
	const [currentCaller, setCurrentCaller] = useState(null);
	const [currentCallInfo, setCurrentCallInfo] = useState(null);
  
	const onIncomingCall = (callerName, extraHeaders, callInfo, caller_Name) => {
	  console.info('onIncomingCall:', callerName, extraHeaders, callInfo, caller_Name);
  
	  setIncomingCallStatus(true);
	  setCurrentCaller(caller_Name);
	  setCurrentCallInfo(callInfo);
  
	  // Store call information
	  callStorage = {
		startTime: new Date().toISOString(),
		mode: 'in',
		num: caller_Name,
	  };
  
	  // Display notification and handle call actions
    notyf.success(`Incoming Call: ${caller_Name}`);
	  
	  // Here, instead of adding buttons to the notification, we'll handle actions through the UI
	  if (callInfo) {
		incomingNotifications.set(callInfo.callUUID, null);
	  }
  
	  // Simulate notification with buttons (or directly handle this in your component's UI)
	  displayCallOptions(callInfo, setIncomingCallStatus);
	};
  
	const displayCallOptions = (callInfo, setIncomingCallStatus) => {
	  // This could be a modal or some UI that gives options to Answer, Reject, or Ignore the call
	  const handleAnswer = () => {
		setIncomingCallStatus(false);
		if (callInfo) {
		  plivoBrowserSdk.client.answer(callInfo.callUUID);
		} else {
		  plivoBrowserSdk.client.answer();
		}
	  };
  
	  const handleReject = () => {
		setIncomingCallStatus(false);
		if (callInfo) {
		  plivoBrowserSdk.client.reject(callInfo.callUUID);
		} else {
		  plivoBrowserSdk.client.reject();
		}
	  };
  
	  const handleIgnore = () => {
		setIncomingCallStatus(false);
		if (callInfo) {
		  plivoBrowserSdk.client.ignore(callInfo.callUUID);
		} else {
		  plivoBrowserSdk.client.ignore();
		}
	  };
  
	  // Here you would show a modal or some UI to handle these actions
	  console.log('Show call options: Answer, Reject, Ignore');
	};
}
const onMediaPermission = (evt) => {
  console.info('WebRTC onMediaPermission', evt);
  if (evt.error) {
    customAlert('Media permission error', evt.error, 'warn');
    if (plivoBrowserSdk.client.browserDetails.browser === "chrome")
      $('#mediaAccessBlock').modal('show');
  }
};

const remoteAudioStatus = (hasAudio) => {
  console.log("Received remoteAudioStatus is ", hasAudio);
  customAlert(`remoteAudioStatus: ${hasAudio}`, "info", 'info');
};

const mediaMetrics = (obj) => {
  console.log("WebRTC Media Metrics Received");
  sessionStorage.setItem('triggerFB', true);
  console.table([obj]);
  const classExist = document.querySelector('.-' + obj.type);
  let message = obj.type;

  if (obj.type.match('audio') && obj.value > 1) {
    message = "same level";
  }

  if (obj.active) {
    if (classExist) classExist.remove();
    const closeAlert = Math.random().toString(36).substring(7);
    $(".oncallalertmsg").append(
      <div id="alert${closeAlert}" class="metrics -${obj.type}">
        <span>${obj.level} | </span>
        <span>${message} : ${obj.value} | </span><span>${obj.desc}</span>
        <span aria-hidden="true" onclick="closeMetrics(this)" style="margin-left:15px;cursor:pointer;">X</span>
      </div>
    );
    setTimeout(() => {
      $(`#alert${closeAlert}`).remove();
    }, 5000);
  }

  if (!obj.active && classExist) {
    document.querySelector('.-' + obj.type).remove();
  }

  if (obj.desc === "no access to your microphone") {
    $('#micAccessBlock').modal({ show: true });
  }
};

const audioDeviceChange = (e) => {
  console.log('audioDeviceChange', e);
  if (e.change) {
    if (e.change === "added") {
      if (e.device.kind === 'audioinput') {
        setTimeout(() => {
          plivoBrowserSdk.client.audio.microphoneDevices.set(e.device.deviceId);
        }, 1000);
      } else {
        setTimeout(() => {
          plivoBrowserSdk.client.audio.speakerDevices.set(e.device.deviceId);
        }, 1000);
      }
      customAlert(e.change, e.device.kind + " - " + e.device.label, 'info');
    } else {
      customAlert(e.change, e.device.kind + " - " + e.device.label, 'warn');
    }
  } else {
    customAlert('info', 'There is an audioDeviceChange but mediaPermission is not allowed yet');
  }
};

const onPermissionDenied = (cause) => {
  console.log('onPermissionDenied: ', cause);
  customAlert(cause, 'warn');
};

const onNoiseReductionReady = () => {
  console.log("Noise Reduction is ready to be started");
};

const onConnectionChange = (obj) => {
  console.log('onConnectionChange received: ', obj);
  if (obj.state === "connected") {
    console.log(obj.state, "info", 'info');
  } else if (obj.state === "disconnected") {
    if (obj.eventCode && obj.eventReason) {
      customAlert(obj.state + " " + obj.eventCode + " " + obj.eventReason, "info");
    } else if (obj.eventCode && !obj.eventReason) {
      customAlert(obj.state + " " + obj.eventCode, "info");
    } else if (!obj.eventCode && obj.eventReason) {
      customAlert(obj.state + " " + obj.eventReason, "info");
    } else {
      customAlert(obj.state, "info");
    }
  } else {
    console.log("unknown connection state ");
  }
};

const handleOnDtmfReceived = (data) => {
  console.log('**DTMF Received:** Digit:', data);
};

const volume = (audioStats) => {
  const inputVolume = audioStats.inputVolume;
  const outputVolume = audioStats.outputVolume;
  colorPids(Math.floor(inputVolume * 325), 'localaudio');
  colorPids(Math.floor(outputVolume * 325), 'remoteaudio');
};

const checkBrowserComplaince = (client) => {
  if (client.browserDetails.browser !== "chrome") {
    document.querySelectorAll('[href="#popAudioDevices"]').forEach(el => el.remove());
  }
};

const starFeedback = () => {
  $('#stars li').on('mouseover', function () {
    const onStar = parseInt($(this).data('value'), 10);
    $(this).parent().children('li.star').each(function (e) {
      if (e < onStar) {
        $(this).addClass('hover');
      } else {
        $(this).removeClass('hover');
      }
    });
  }).on('mouseout', function () {
    $(this).parent().children('li.star').each(function (e) {
      $(this).removeClass('hover');
    });
  });

  $('#stars li').on('click', function () {
    const onStar = parseInt($(this).data('value'), 10);
    const stars = $(this).parent().children('li.star');
    for (let i = 0; i < stars.length; i++) {
      $(stars[i]).removeClass('selected');
    }
    for (let i = 0; i < onStar; i++) {
      $(stars[i]).addClass('selected');
    }
    const value = parseInt($('#stars li.selected').last().data('value'), 10);
    if (value < 5) {
      $('.lowQualityRadios').show();
    } else {
      $('.lowQualityRadios').hide();
    }
  });
};

export const customAlert = (header, alertMessage, type) => {
  const closeAlert = Math.random().toString(36).substring(7);
  let typeClass = "";
  if (type === "info") {
    typeClass = "alertinfo";
  } else if (type === "warn") {
    typeClass = "alertwarn";
  }
  $(".alertmsg").append(
    <div id="alert${closeAlert}" class="customAlert ${typeClass}">
      <span style="margin-left:20px;">${header} | </span>
      <span style="margin-left:20px;">${alertMessage} </span>
      <span aria-hidden="true" onclick="closeMetrics(this)" style="margin-left:25px;cursor:pointer;">X</span>
    </div>
  );
  setTimeout(() => {
    $(`#alert${closeAlert}`).remove();
  }, 5000);
};

const clearStars = () => {
  const stars = document.querySelectorAll('.star');
  for (let i = 0; i < stars.length; i++) {
    $(stars[i]).removeClass('selected');
  }
  $('[name="callqualitycheck"]').each(function () {
    this.checked = false;
  });
  $('#sendFeedbackComment').val("");
};

const showKeypadInfo = () => {
  const phone = document.getElementById('phone');
  phone.style.removeProperty("width");
  phone.style.removeProperty("margin-left");
  $('#phone').show();
  $('.iti').show();
  $('#toNumber').show();
  $('.calleridinfo').show();
  $('#makecall').show();
  $('.micsettingslink').show();
  $('#showKeypad').html('SHOW KEYPAD');
};

const resetMute = () => {
  $('#tmute').attr('data-toggle', 'mute');
  $('.tmute').attr('class', 'fa tmute fa-microphone fa-lg callinfoIcon');
};

const callOff = (reason) => {
  $('.incomingCallDefault').hide();
  showKeypadInfo();
  resetMute();
  if (timer === "00:00:00" && callStorage.mode === "in") {
    callStorage.mode = "missed";
  }
  $('#callstatus').html('Idle');
  callStorage = {}; // reset callStorage
};

// Helper function to color volume bars
const colorPids = (vol, volumeType) => {
  const all_pids = $('.pid' + volumeType);
  const amount_of_pids = Math.round(vol / 10);
  const elem_range = all_pids.slice(0, amount_of_pids);
  for (let i = 0; i < all_pids.length; i++) {
    all_pids[i].style.backgroundColor = "#e6e7e8";
  }
  for (let j = 0; j < elem_range.length; j++) {
    elem_range[j].style.backgroundColor = "#69ce2b";
  }
};

export {
  onCallRemoteRinging,
  onIncomingCallCanceled,
  onIncomingCallIgnored,
  //onCallFailed,
  onMediaConnected,
  onCalling,
  onMediaPermission,
  remoteAudioStatus,
  mediaMetrics,
  audioDeviceChange,
  onPermissionDenied,
  onNoiseReductionReady,
  onConnectionChange,
  handleOnDtmfReceived,
  volume,
  checkBrowserComplaince,
  starFeedback,
  clearStars,
  showKeypadInfo,
  resetMute,
  callOff,
  plivoBrowserSdk
};