// components/Logo.js
import React from 'react';
import { Typography, Space } from 'antd';
import { FireOutlined } from '@ant-design/icons';

const { Text } = Typography;

function Logo() {
  return (
    <Space align="center" style={{ margin: '16px', marginTop: '32px'}}>
      <img src='favicon.png' alt='Matchstick Logo' style={{ width: '64px', height: '64px', top: '-5px', position: 'relative' }} />
      <Text
        style={{
          fontSize: '28px',
          color: '#00bcd4',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 300,
          fontStyle: 'italic',
          letterSpacing: '0.5px',
        }}
      >
        Matchstick <span style={{fontSize: '15px',
          color: '#00bcd4',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 300,
          fontStyle: 'normal',
          letterSpacing: '0.5px',}}>by PBJ Digital</span>
      </Text>
    </Space>
  );
}

export default Logo;