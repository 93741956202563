import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, message, Table, Popconfirm, Select } from 'antd';
import axiosInstance from './axiosConfig';

const ClientManagement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [form] = Form.useForm();
    const [clients, setClients] = useState([]);
    const [editingClient, setEditingClient] = useState(null);

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await axiosInstance.get('/clients');
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
            message.error('Error fetching clients');
        }
    };

    const handleCreateButtonClick = () => {
        setEditingClient(null);
        setIsOpen(true);
    };

    const handleEditButtonClick = (client) => {
        setEditingClient(client);
        form.setFieldsValue(client);
        setIsOpen(true);
    };

    const handleDeleteClient = async (id) => {
        try {
            await axiosInstance.delete(`/clients/${id}`);
            message.success('Client deleted successfully');
            fetchClients();
        } catch (error) {
            console.error('Error deleting client:', error);
            message.error('Error deleting client');
        }
    };

    const handleSubmit = async (values) => {
        try {
            if (editingClient) {
                await axiosInstance.put(`/clients/${editingClient.id}`, values);
                message.success('Client updated successfully');
            } else {
                await axiosInstance.post('/create-client', values);
                message.success('Client created successfully');
            }
            fetchClients();
            closeModal();
        } catch (error) {
            console.error('Error saving client:', error);
            message.error('Error saving client');
        }
    };

    const handleReportIntervalChange = async (id, reportInterval) => {
        try {
            await axiosInstance.put(`/clients/${id}/report-interval`, { reportInterval });
            message.success('Report interval updated successfully');
            fetchClients();
        } catch (error) {
            console.error('Error updating report interval:', error);
            message.error('Error updating report interval');
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        form.resetFields();
    };

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'contactFName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'contactLName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'contactEmail',
            key: 'email',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Report Interval',
            key: 'reportInterval',
            render: (text, record) => (
                <Select
                    defaultValue={record.reportInterval}
                    style={{ width: 150 }}
                    onChange={(value) => handleReportIntervalChange(record.id, value)}
                >
                    <Select.Option value={0}>No Reports</Select.Option>
                    <Select.Option value={1}>Once a Month</Select.Option>
                    <Select.Option value={2}>Twice a Month</Select.Option>
                    <Select.Option value={3}>Weekly</Select.Option>
                </Select>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Button type="link" onClick={() => handleEditButtonClick(record)}>Edit</Button>
                    <Popconfirm title="Are you sure delete this client?" onConfirm={() => handleDeleteClient(record.id)} okText="Yes" cancelText="No">
                        <Button type="link" danger>Delete</Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div>
            <Button type="primary" onClick={handleCreateButtonClick}>Create New Client</Button>
            <Table dataSource={clients} columns={columns} rowKey="id" style={{ marginTop: 20 }} />

            <Modal
                visible={isOpen}
                title={editingClient ? "Edit Client" : "Create New Client"}
                onCancel={closeModal}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter the first name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter the last name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Company Name"
                        name="companyName"
                        rules={[{ required: true, message: 'Please enter the company name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {editingClient ? "Update" : "Submit"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ClientManagement;
