// src/components/CallDataTable.js

import React, { useEffect, useState } from 'react';
import { Table, Button, Tag } from 'antd';
import axios from 'axios';

const CallDataTable = ({
  isCompact,
  onRowClick,
  rowClassName,
  selectedRowKey,
  user,
  setSelectedRow,
}) => {
  const [tableData, setTableData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });

  // Fetch data from the server
  const fetchData = () => {
    const queryParams = new URLSearchParams();

    queryParams.append('page', pagination.current);
    queryParams.append('pageSize', pagination.pageSize);

    // Enforce filter by fname if user is provided
    if (user && user.fname) {
      filteredInfo.fname = [user.fname];
    }

    if (Object.keys(filteredInfo).length > 0) {
      queryParams.append('filters', JSON.stringify(filteredInfo));
    }

    if (sortedInfo.field && sortedInfo.order) {
      queryParams.append('sorter', JSON.stringify(sortedInfo));
    }

    axios
      .get(`https://gomatchstick.co/api/latest-data?${queryParams.toString()}`)
      .then((res) => {
        const data = res.data;
        console.log('API Response Data:', data); // For debugging

        // Ensure data is an array
        const dataArray = Array.isArray(data) ? data : data.data;
        if (!Array.isArray(dataArray)) {
          console.error('Data fetched is not an array:', dataArray);
          setTableData([]);
          return;
        }

        // Map the data
        let mappedData = dataArray.map((item, index) => {
          // Adjust the mapping based on your actual data structure
          console.log(item)
          return {
              key: index.toString(),
              ...item, // Include the entire item if needed
            ...item.data.callDetails, // Adjust according to your data
            ...item.summary, // Adjust according to your data
            callid: item.data.callid, // Include callid if needed
            userId: item.data.userId, // The caller's ID
            hookevent: item.hookevent, // If necessary
          };
        });

        console.log('Mapped Data before filtering duplicates:', mappedData);

        // Filter data by user fname if user is provided
        if (user && user.fname) {
          mappedData = mappedData.filter((record) => record.fname === user.fname);
        }

        // Sort the data to bring duplicates next to each other
        const sortedData = mappedData.sort((a, b) => {
          // Compare userId (caller)
          if (a.userId !== b.userId) {
            return a.userId.localeCompare(b.userId);
          }
          // Compare call date
          const dateA = new Date(a.calldate).getTime();
          const dateB = new Date(b.calldate).getTime();
          if (dateA !== dateB) {
            return dateA - dateB;
          }
          // Compare call duration
          if (a.duration !== b.duration) {
            return a.duration - b.duration;
          }
          // All fields are equal
          return 0;
        });

        // Filter out duplicates
        const uniqueData = [];
        for (let i = 0; i < sortedData.length; i++) {
          const currentRecord = sortedData[i];
          const previousRecord = sortedData[i - 1];

          if (
            i > 0 &&
            currentRecord.userId === previousRecord.userId &&
            currentRecord.duration === previousRecord.duration &&
            new Date(currentRecord.calldate).getTime() ===
              new Date(previousRecord.calldate).getTime()
          ) {
            // Duplicate record, skip
            continue;
          } else {
            uniqueData.push(currentRecord);
          }
        }

        console.log('Mapped Data after filtering duplicates:', uniqueData);

        setTableData(uniqueData);
        setPagination((prev) => ({
          ...prev,
          total: data.total || uniqueData.length,
        }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  // Fetch data when dependencies change
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize, filteredInfo, sortedInfo]);

  // Handle table changes
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: pagination.total,
    });

    // Clean filters by removing empty values
    const cleanedFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key] && filters[key].length > 0) {
        acc[key] = filters[key];
      }
      return acc;
    }, {});

    // Enforce filter by fname if user is provided
    if (user && user.fname) {
      cleanedFilters.fname = [user.fname];
    }

    setFilteredInfo(cleanedFilters);

    if (sorter.order) {
      setSortedInfo({
        field: sorter.field,
        order: sorter.order,
        columnKey: sorter.columnKey,
      });
    } else {
      setSortedInfo({});
    }
  };

  // Define your columns
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'fname',
      key: 'fname',
      filters:
        user && user.fname
          ? null
          : [
              { text: 'John', value: 'John' },
              { text: 'Paul', value: 'Paul' },
              { text: 'Christian', value: 'Christian' },
              { text: 'Manuel', value: 'Manuel' },
            ],
      filteredValue: filteredInfo.fname || null,
      onFilter: (value, record) => record.fname?.includes(value),
      sorter: (a, b) => (a.fname || '').localeCompare(b.fname || ''),
      sortOrder: sortedInfo.columnKey === 'fname' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lname',
      key: 'lname',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => <Tag color={record?.company_color}>{text}</Tag>,
    },
    {
      title: 'MatchBoxID',
      dataIndex: 'matchBoxId',
      key: 'matchBoxId',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => a.duration - b.duration,
      sortOrder: sortedInfo.columnKey === 'duration' ? sortedInfo.order : null,
      render: (text) => `${text} seconds`,
    },
    {
      title: 'Call Date',
      dataIndex: 'calldate',
      key: 'calldate',
      sorter: (a, b) => new Date(a.calldate) - new Date(b.calldate),
      sortOrder: sortedInfo.columnKey === 'calldate' ? sortedInfo.order : null,
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Recording URL',
      dataIndex: 'recordingurl',
      key: 'recordingurl',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Listen
        </a>
      ),
    },
    // Add more columns as needed
  ];

  // Compact columns
  const columnsCompact = [
    {
      title: 'First Name',
      dataIndex: 'fname',
      key: 'fname',
      filters:
        user && user.fname
          ? null
          : [
              { text: 'John', value: 'John' },
              { text: 'Paul', value: 'Paul' },
              { text: 'Christian', value: 'Christian' },
              { text: 'Manuel', value: 'Manuel' },
            ],
      filteredValue: filteredInfo.fname || null,
      onFilter: (value, record) => record.fname?.includes(value),
      sorter: (a, b) => (a.fname || '').localeCompare(b.fname || ''),
      sortOrder: sortedInfo.columnKey === 'fname' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'List Name',
      dataIndex: 'listName',
      key: 'listName',
      sorter: (a, b) => (a.listName || '').localeCompare(b.listName || ''),
      sortOrder: sortedInfo.columnKey === 'listName' ? sortedInfo.order : null,
    },
    // Add more columns as needed
  ];

  // Remove the clear filters buttons if user is provided
  const showClearFilters = !(user && user.fname);

  const clearFilters = () => setFilteredInfo({});
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  return (
    <>
      <Table
        dataSource={tableData}
        scroll={{ x: 400 }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          pageSizeOptions: ['8', '10', '20', '50'],
        }}
        size="small"
        columns={isCompact ? columnsCompact : columns}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record);
            setSelectedRow(record);
          },
        })}
        rowClassName={rowClassName}
      />
      {showClearFilters && (
        <>
          <Button onClick={clearFilters}>Clear filters</Button>
          <Button onClick={clearAll}>Clear filters and sorters</Button>
        </>
      )}
    </>
  );
};

export default CallDataTable;
