// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (authToken) {
      try {
        const decoded = jwtDecode(authToken);
        setUser({
          id: decoded.userId,
          username: decoded.username,
          role: decoded.role,
          companyId: decoded.companyId,
          // Add other relevant fields from your JWT payload
        });
        console.log(decoded);
        console.log(decoded.role);
        console.log(decoded.companyId);
      } catch (error) {
        console.error('Invalid token:', error);
        setAuthToken(null);
        setUser(null);
        localStorage.removeItem('authToken');
      }
    }
  }, [authToken]);

  const login = (token) => {
    setAuthToken(token);
    localStorage.setItem('authToken', token);
    window.location.reload();
    try {
      const decoded = jwtDecode(token);
      setUser({
        id: decoded?.userId,
        username: decoded?.username,
        role: decoded?.role,
        companyId: decoded?.companyId,
        // Add other relevant fields from your JWT payload
      });
    } catch (error) {
      console.error('Invalid token during login:', error);
      setAuthToken(null);
      setUser(null);
      localStorage.removeItem('authToken');
    }
  };

  const logout = () => {
    setAuthToken(null);
    setUser(null);
    localStorage.removeItem('authToken');
  };

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};