import React from 'react';
import { APP_VERSION } from '../version';

function Footer({ color, fontColor }) {
    return (
        <footer style={{ color: fontColor, backgroundColor: color, textAlign: 'center', fontFamily: 'Arial', fontSize: '18px', padding: '10px' }}>
            <p>© PBJ Digital 2024 | Matchstick Version ⍺{APP_VERSION}</p>
        </footer>
    );
}

export default Footer;
