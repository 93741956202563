// CallHistorySearch.js

import React, { useState, useEffect } from 'react';
import { CreateMLCEngine } from '@mlc-ai/web-llm'; // Named import
import axios from 'axios';

const CallHistorySearch = () => {
  const [engine, setEngine] = useState(null);
  const [userQuery, setUserQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loadingModel, setLoadingModel] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Current page number
  const [totalItems, setTotalItems] = useState(0); // Total items for pagination

  // Initialize the MLCEngine with Llama 3.2 1B Instruct model and extended context
  useEffect(() => {
    const initEngine = async () => {
      const initProgressCallback = (progress) => {
        console.log('Loading Progress:', progress);
      };

      const selectedModel = 'Llama-3.2-3B-Instruct-q4f32_1-MLC'; // Ensure this model exists and supports 128k tokens

      try {
        const engineInstance = await CreateMLCEngine(selectedModel, {
          initProgressCallback,
          // If the library requires explicit context length, set it here
          // contextLength: 128000,
        });

        setEngine(engineInstance);
        console.log('LLM Engine initialized successfully with 128k context.');
      } catch (err) {
        console.error('Error initializing LLM engine:', err);
        setError('Failed to initialize language model. Please try again later.');
      } finally {
        setLoadingModel(false);
      }
    };

    initEngine();
  }, []);

  // Generate the prompt for the LLM with detailed data structure understanding and extended context
  const generatePrompt = (query) => `
You are a highly intelligent assistant specialized in interpreting natural language queries to search through call history data.

The call history data is structured as follows:

{
  "callid": "string", // Unique identifier for the call
  "callDetails": {
    "fname": "string", // First name of the caller
    "lname": "string", // Last name of the caller
    "company_name": "string", // Company associated with the call
    "calldate": "YYYY-MM-DD", // Date of the call
    "duration": integer, // Duration of the call in seconds
    "recordingurl": "string", // URL to the call recording
    "callTitle": "string" // Title or subject of the call
    // Additional fields may be present
  }
}

Your task is to extract the relevant information from the user's query to form search parameters for the API.

Extract the following fields:
- First Name (fname) as an array
- Last Name (lname) as an array
- Company Name (company_name) as an array
- Call Date (calldate) in YYYY-MM-DD format as an array
- Minimum Duration (minDuration) in seconds as an integer
- Call Title (callTitle) as an array

Provide **only** the extracted information in **valid JSON format** like this:
{
  "fname": null,
  "lname": null,
  "company_name": null,
  "calldate": null,
  "minDuration": null,
  "callTitle": null
}

If a particular field is not mentioned in the query, set it to null.

Do not include any additional text or explanations.

User's query: "${query}"
`;

  // Process the user's query using the LLM and fetch filtered call history
  const processQuery = async () => {
    if (!engine) {
      console.error('LLM engine is not initialized.');
      setError('Language model is not ready. Please wait and try again.');
      return;
    }

    if (userQuery.trim() === '') {
      setError('Please enter a search query.');
      return;
    }

    setLoadingSearch(true);
    setError(null);
    setSearchResults([]);
    setTotalItems(0);

    const prompt = generatePrompt(userQuery);

    try {
      const response = await engine.chat.completions.create({
        messages: [
          {
            role: 'system',
            content:
              'You are an assistant that helps process search queries.',
          },
          { role: 'user', content: prompt },
        ],
      });

      const assistantReply = response.choices[0].message.content;
      console.log('Assistant Reply:', assistantReply);

      // Attempt to extract JSON from the assistant's reply
      const jsonMatch = assistantReply.match(/\{[\s\S]*\}/);
      if (jsonMatch) {
        let extractedInfo;
        try {
          extractedInfo = JSON.parse(jsonMatch[0]);
          console.log('Extracted Info:', extractedInfo);
        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
          setError('Failed to interpret your query. Please try rephrasing it.');
          setLoadingSearch(false);
          return;
        }

        // Proceed to fetch call history with extractedInfo
        fetchFilteredCallHistory(extractedInfo);
      } else {
        console.error('No JSON found in the assistant\'s reply.');
        setError('Failed to interpret your query. Please try rephrasing it.');
        setLoadingSearch(false);
      }
    } catch (err) {
      console.error('Error processing query:', err);
      setError('An error occurred while processing your query. Please try again.');
      setSearchResults([]);
      setLoadingSearch(false);
    }
  };

  // Fetch filtered call history from the server based on criteria
  const fetchFilteredCallHistory = async (criteria) => {
    try {
      // Build filters object based on criteria, ensuring each value is an array
      const filters = {};
      if (criteria.fname) {
        filters.fname = Array.isArray(criteria.fname) ? criteria.fname : [criteria.fname];
      }
      if (criteria.lname) {
        filters.lname = Array.isArray(criteria.lname) ? criteria.lname : [criteria.lname];
      }
      if (criteria.company_name) {
        filters.company = Array.isArray(criteria.company_name) ? criteria.company_name : [criteria.company_name];
      }
      if (criteria.calldate) {
        filters.calldate = Array.isArray(criteria.calldate) ? criteria.calldate : [criteria.calldate];
      }
      if (criteria.minDuration) {
        filters.minDuration = [criteria.minDuration];
      }
      if (criteria.callTitle) {
        filters.callTitle = Array.isArray(criteria.callTitle) ? criteria.callTitle : [criteria.callTitle];
      }

      if (Object.keys(filters).length === 0) {
        setError('No valid search criteria extracted from your query. Please refine your search.');
        setLoadingSearch(false);
        return;
      }

      // Serialize filters to JSON string
      const filtersJSON = JSON.stringify(filters);

      // Define sorter. For now, use default sorting by calldate DESC
      const sorter = {
        field: 'calldate',
        order: 'descend',
      };
      const sorterJSON = JSON.stringify(sorter);

      // Define pagination parameters
      const currentPage = page;
      const itemsPerPage = 10; // Fixed page size

      console.log('Fetching with filters:', filtersJSON);
      console.log('Fetching with sorter:', sorterJSON);
      console.log('Page:', currentPage);
      console.log('Page Size:', itemsPerPage);

      const response = await axios.get('https://gomatchstick.co/api/latest-data', {
        params: {
          page: currentPage,
          pageSize: itemsPerPage,
          filters: filtersJSON,
          sorter: sorterJSON,
        },
      });

      const data = response.data;

      // Ensure data is an array
      const dataArray = Array.isArray(data.data) ? data.data : [];
      if (!Array.isArray(dataArray)) {
        console.error('Data fetched is not an array:', dataArray);
        setError('Received unexpected data format from the server.');
        setSearchResults([]);
        setTotalItems(0);
        return;
      }

      // Map the data to match the call history structure
      const mappedCallHistory = dataArray.map((item) => {
        // Adjust the mapping based on your actual data structure
        const callDetails = item.callDetails || {};

        return {
          id: item.callid,
          fname: callDetails.fname || '',
          lname: callDetails.lname || '',
          company: callDetails.company_name || '',
          calldate: callDetails.calldate || '',
          duration: callDetails.duration || 0,
          recordingurl: callDetails.recordingurl || '',
          callTitle: callDetails.callTitle || 'No Title',
          // Add other fields as needed
        };
      });

      setSearchResults(mappedCallHistory);
      setTotalItems(data.total);
      console.log('Filtered Call History:', mappedCallHistory);
    } catch (err) {
      console.error('Error fetching filtered call history:', err);
      setError('Failed to fetch call history data. Please try again.');
      setSearchResults([]);
      setTotalItems(0);
    } finally {
      setLoadingSearch(false);
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setUserQuery(e.target.value);
  };

  // Handle search button click
  const handleSearch = () => {
    // Reset to first page on new search
    setPage(1);
    processQuery();
  };

  // Handle pagination: Next and Previous
  const handleNextPage = () => {
    if (page * 10 < totalItems) { // Fixed page size of 10
      setPage((prevPage) => prevPage + 1);
      processQuery(); // Fetch next page
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
      processQuery(); // Fetch previous page
    }
  };

  // Render the search results
  const renderResults = () => {
    if (loadingSearch) {
      return <p>Searching...</p>;
    }

    if (searchResults.length === 0) {
      return <p>No call history items found.</p>;
    }

    return (
      <>
        <ul style={styles.list}>
          {searchResults.map((call) => (
            <li key={call.id} style={styles.listItem}>
              <h3>{call.callTitle}</h3>
              <p><strong>Date:</strong> {call.calldate}</p>
              <p><strong>Duration:</strong> {call.duration} seconds</p>
              <p><strong>Name:</strong> {call.fname} {call.lname}</p>
              <p><strong>Company:</strong> {call.company}</p>
              {call.recordingurl && (
                <a href={call.recordingurl} target="_blank" rel="noopener noreferrer">
                  Listen to Recording
                </a>
              )}
            </li>
          ))}
        </ul>
        <div style={styles.pagination}>
          <button
            onClick={handlePreviousPage}
            disabled={page === 1}
            style={{
              ...styles.paginationButton,
              ...(page === 1 ? styles.disabledButton : {}),
            }}
          >
            Previous
          </button>
          <span style={styles.pageInfo}>Page {page} of {Math.ceil(totalItems / 10)}</span>
          <button
            onClick={handleNextPage}
            disabled={page * 10 >= totalItems}
            style={{
              ...styles.paginationButton,
              ...(page * 10 >= totalItems ? styles.disabledButton : {}),
            }}
          >
            Next
          </button>
        </div>
      </>
    );
  };

  return (
    <div style={styles.container}>
      <h1>Call History Search</h1>
      {loadingModel ? (
        <p>Loading language model, please wait...</p>
      ) : (
        <>
          <div style={styles.searchBox}>
            <input
              type="text"
              value={userQuery}
              onChange={handleInputChange}
              placeholder="e.g., Find calls from John Doe at Acme Corp on 2024-09-10."
              style={styles.input}
              aria-label="Search call history"
            />
            <button onClick={handleSearch} style={styles.button}>
              Search
            </button>
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <div style={styles.results}>{renderResults()}</div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '40px auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  },
  searchBox: {
    display: 'flex',
    marginBottom: '20px',
  },
  input: {
    flexGrow: 1,
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  button: {
    padding: '12px 24px',
    fontSize: '16px',
    marginLeft: '10px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
  },
  results: {
    marginTop: '20px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    padding: '15px',
    borderBottom: '1px solid #eee',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  paginationButton: {
    padding: '8px 16px',
    margin: '0 10px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
  },
  disabledButton: {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  },
  pageInfo: {
    fontSize: '16px',
  },
};

export default CallHistorySearch;
